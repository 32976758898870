import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

enum TokenType {
  Pets = "pets",
  Cats = "cats",
  Fractures = "fractures",
  Cooltopia = "cooltopia",
  Wolves = "wolf",
}

interface TokenData {
  id: number;
  address: string;
  staked: boolean;
  url: string;
  type: TokenType;
}

const fetchCoolScore = async (address: string) => {
  try {
    const response = await API.get(`score/getCoolScore/${address}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchAnswer = async (journeyNumber: number, address: string) => {
  try {
    const response = await API.get(
      `stake/getAnswer/${address}/${journeyNumber}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchTokens = async (
  address: string,
  type: TokenType,
  journeyNumber: any
): Promise<TokenData[]> => {
  try {
    const response = await API.get(
      `/stake/getTokens/${address}/${type}/${journeyNumber}`
    );
    console.log({ response });

    return response.data.map((el: any) => {
      // const r = axios.get(`https://api.coolcatsnft.com/cat/${el.id}`);
      return {
        id: el.id,
        address: el.address,
        staked: el.staked,
        // url: r.data?.ipfs_image || "",
        url: "",
        type,
      };
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const stakeTokens = async (data: any, journeyNumber: any) => {
  try {
    const response = await API.post(`/stake/stakeTokens/${journeyNumber}`, data);
    console.log({ response });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const endJourney = async (data: any) => {
  try {
    const response = await API.post(`/stake/endJourney`, data);
    console.log({ response });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const hasJoinedJourney = async (journeyNumber: any, address: any) => {
  try {
    const response = await API.get(
      `stake/hasJoinedJourney/${journeyNumber}/${address}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const hasEndedJourney = async (journeyNumber: any, address: any) => {
  try {
    const response = await API.get(
      `stake/journeyEnded/${journeyNumber}/${address}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const hasRunningJourney = async (address: any) => {
  try {
    const response = await API.get(`stake/getRunningJourneys/${address}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchReward = async (address: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_REWARD_API}${address}?token_type=frac&check=true`
      // `https://dev-claw.coolcatsapi2.com/tokens/collection/grampabacon.eth?token_type=frac&check=true`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchAchievements = async (address: any) => {
  try {
    const response = await API.get(`users/getStaticTokens/${address}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const sendAchievements = async (data: any) => {
  try {
    const response = await API.post(`users/sendAchievements`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchTransferRequests = async (address: any) => {
  try {
    const response = await API.get(`users/receiveAchievements/${address}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const transferAchievements = async (data: any) => {
  try {
    const response = await API.post(`users/transferAchievements`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export {
  fetchCoolScore,
  fetchAnswer,
  fetchTokens,
  stakeTokens,
  endJourney,
  hasJoinedJourney,
  hasEndedJourney,
  hasRunningJourney,
  fetchReward,
  TokenType,
  fetchAchievements,
  sendAchievements,
  transferAchievements,
  fetchTransferRequests
};
export type { TokenData };
