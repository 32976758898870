import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "../../atoms/Button";
import { MetaMaskContext } from "../../../hoc/Metamask";
import { Modal, notification, Spin, Popover } from "antd";
import { limitCharacter } from "../../../helpers/limitCharacter";
import { deviceWidth } from "../../../config/theme";
import styled from "styled-components";
import { HOMEPAGE, JOURNEY7 } from "../../../constants/images";
import Countdown from "react-countdown";
import moment from "moment-timezone";
import { CoolScore, JourneyScore, MaxScore } from "../../molecules/Score";
import {
  fetchAnswer,
  fetchCoolScore,
  fetchTokens,
  stakeTokens,
  endJourney,
  hasJoinedJourney,
  hasEndedJourney,
  hasRunningJourney,
  fetchReward,
  TokenData,
  TokenType,
} from "src/services/api";
import {
  getByID,
  getToBeStakedIds,
  getToBeUnstakedIds,
  getAlreadyStakedIds,
  getCount,
  getStakedData,
  getUnstakedData,
  getRandom,
} from "src/helpers/stakeSelectors";
import { useAccount, useSignMessage } from "wagmi";
import { StakeSelection } from "../../molecules/StakeSelection";
import { ContentModal } from "../../molecules/ContentModal";
import { PuzzleModal } from "../../molecules/PuzzleModal";
import { StakeModal } from "../../molecules/StakeModal";
import { ConfirmModal } from "../../molecules/ConfirmModal";
import { CompleteModal } from "../../molecules/CompleteModal";
import { RewardModal } from "../../molecules/RewardModal";
import JourneyCard from "../../molecules/JourneyCard";
import { useScreenDetector } from "src/hooks";
import { embedVideoURL, imageAPIURL } from "src/constants";
import { WarningModal } from "../../molecules/WarningModal";
import { Overlay } from "../../molecules/Overlay";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useWeb3Modal } from "@web3modal/react";
import axios from "axios";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { formattedDate } from "src/helpers/formatters";
import LineTo from "react-lineto";
import {
  isJourneyActive,
  isJourneyCompleted,
  isJourneyLocked,
} from "src/helpers/journey";
import coolcatsJourneys from "src/constants/journeys";
import shadowJourneys from "src/constants/shadowJourneys";
import { ScoreContext } from "src/hoc/ScoreProvider";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { MapComponent } from "../../molecules/MapComponent";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { SlidePuzzle } from "../../atoms/SlidePuzzle";
import { AnswerModal } from "../../molecules/AnswerModal";
import { PauseOverlay } from "../../atoms/PauseOverlay";
import { DeskPuzzle } from "../../atoms/DeskPuzzle";
import merkelProof from "../../../constants/merkel-tree";
import { DragPuzzle } from "../../atoms/DragPuzzle";

const upcomingJourney = 18;

export default function Home() {
  //Utility
  const { isConnected } = useAccount();
  const { address } = useAccount();
  // const [address, setAddress] = useState(
  //   "0xb5b35a9e63edea2a43399632bcef85ce879b29c1"
  // );

  const [sidebarOpen, setSidebarOpen] = useState(true);

  //Active Journey
  const [activeJourney, setActiveJourney] = useState<number | null>(null);
  const [incompleteJourney, setIncompleteJourney] = useState<number | null>(
    null
  );

  //Join Journey Modals
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isPuzzleModalOpen, setIsPuzzleModalOpen] = React.useState(false);
  const [isStakeModalOpen, setIsStakeModalOpen] = React.useState(false);
  const [selectionModal, setSelectionModal] = React.useState<string | null>(
    null
  );
  const [loading, setIsLoading] = React.useState(false);
  const [isStaking, setIsStaking] = React.useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [disableStakeContinueButton, setDisableStakeContinueButton] =
    React.useState<boolean>(true);
  const [openPuzzleAnswer, setOpenPuzzleAnswer] = React.useState(false);

  //End Journey Modals
  const [isCompleteModalOpen, setIsCompleteModalOpen] = React.useState(false);
  const [isRewardModalOpen, setIsRewardModalOpen] = React.useState(false);
  const [journeys, setJourneys] = useState<any[]>([]);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [txnHash, setTxnHash] = useState("");
  const [hasMinted, setHasMinted] = useState(false);

  const [showMaskMobile, setShowMaskMobile] = useState<boolean>(true);


  //ScoreContext
  const {
    coolScore,
    setCoolScore,
    journeyScore,
    selectedCats,
    setSelectedCats,
    selectedPets,
    setSelectedPets,
    selectedCooltopia,
    setSelectedCooltopia,
    catsData,
    setCatsData,
    petsData,
    setPetsData,
    cooltopiaData,
    setCooltopiaData,
    wolvesData,
    setWolvesData,
    selectedWolves,
    setSelectedWolves,
    answer,
    setAnswer,
  } = useContext(ScoreContext);

  //Find currently active journey based  active date period
  const getActiveJourney = (address: any) => {
    for (const journey of coolcatsJourneys) {
      if (isJourneyActive(journey.startDate, journey.stakingCloseDate)) {
        setActiveJourney(journey.number);
        setJourneyPopoverOpen(journey.number);
        setJourneys(coolcatsJourneys);
        break;
      }
    }
  };

  useEffect(() => {
    if (!activeJourney) {
      setActiveJourney(upcomingJourney);
    }
  }, [activeJourney]);

  const previousJourneyEnded = async (journeyNumber: number) => {
    const { result: oldResult } = await hasRunningJourney(address);
    const result = oldResult.filter((el: number) => el !== (upcomingJourney - 1));
    if (result.length !== 0 && activeJourney && result[0] < activeJourney) {
      setIncompleteJourney(result[0]);
      return false;
    }
    //Test
    // setIncompleteJourney(8);
    // return false;
    return true;
  };

  //Modal control functions
  const showModal = async (journeyNumber: number) => {
    switch (journeyNumber) {
      case 1:
        setIsModalOpen(true);
        break;
      default:
        // setIsModalOpen(true);
        (await previousJourneyEnded(journeyNumber))
          ? setIsModalOpen(true)
          : setIsCompleteModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePuzzleModal = () => {
    setIsPuzzleModalOpen(true);
    handleCancel();
  };
  const handlePuzzleModalCancel = () => {
    setIsPuzzleModalOpen(false);
  };

  const handlePuzzleModalBack = () => {
    setIsPuzzleModalOpen(false);
    setIsModalOpen(true);
  };

  const handleStakeModal = () => {
    setIsStakeModalOpen(true);
    handlePuzzleModalCancel();
    // handleCancel();
  };
  const handleStakeModalCancel = () => {
    setIsStakeModalOpen(false);
  };

  const handleStakeModalBack = () => {
    setIsStakeModalOpen(false);
    setIsPuzzleModalOpen(true);
    // setIsModalOpen(true);
  };

  const selectAll = () => {
    setSelectedCats(catsData.filter((el) => !el.staked).map((el) => el.id));
    setSelectedPets(petsData.filter((el) => !el.staked).map((el) => el.id));
    setSelectedCooltopia(
      cooltopiaData.filter((el) => !el.staked).map((el) => el.id)
    );
  };

  const selectAllWolves = () => {
    setSelectedWolves(wolvesData.filter((el) => !el.staked).map((el) => el.id));
  };

  const [path, setPath] = useState("");
  const [journeyParticipated, setJourneyParticipated] = useState(false);
  const [journeyEnded, setJourneyEnded] = useState(false);

  const resetData = () => {
    setAnswer("");
    setSelectedCats([]);
    setSelectedPets([]);
    setSelectedCooltopia([]);
    setSelectedWolves([]);
    // setCatsData([]);
    // setPetsData([]);
    // setCooltopiaData([]);
    // setWolvesData([]);
  };

  
async function fetchData(journeyNumber: number, address: string) {
    setIsLoading(true);

    const cats = await fetchTokens(address, TokenType.Cats, journeyNumber);
    const pets = await fetchTokens(address, TokenType.Pets, journeyNumber);
    const cooltopia = await fetchTokens(
      address,
      TokenType.Cooltopia,
      journeyNumber
    );
    if (journeys && journeys[journeyNumber - 1].type === "shadow") {
      const wolves = await fetchTokens(
        address,
        TokenType.Wolves,
        journeyNumber
      );
      setWolvesData(wolves);
    }
    fetchAnswer(journeyNumber, address).then((response) => {
      setAnswer(response?.answer);
      setPath(response?.path);
    });
    console.log("CATS", cats)
    setCatsData(cats);
    setPetsData(pets);
    setCooltopiaData(cooltopia);
    setIsLoading(false);
  }

  useEffect(() => {
    if (address && activeJourney && journeys) {
      fetchData(activeJourney, address);
      hasJoinedJourney(activeJourney, address).then((response) => {
        setJourneyParticipated(response?.result === true);
        if (response?.result === true) {
          hasEndedJourney(activeJourney, address).then((response) => {
            setJourneyEnded(response?.result === true);
          });
        }
      });
    }
  }, [activeJourney, address, journeys]);

  useEffect(() => {
    console.log("Cats", catsData);
  }, [catsData])

  useEffect(() => {
    if (address) {
      fetchCoolScore(address).then((response) => {
        console.log("Coolscore: ", response?.coolScore);
        setCoolScore(Math.round(Number(response?.coolScore)));
      });
      // fetchAnswer(activeJourney, address).then((response) => {
      //   console.log("Answer: ", response?.answer);
      //   setAnswer(response?.answer);
      //   setPath(response?.path);
      // });
    } else {
      setCoolScore(0);
      // setTempAnswer("");
      // setAnswer("");
      // setPath("");
    }
  }, [address]);


  useEffect(() => {
    resetData();
    if (address) {
      getActiveJourney(address);
    }
  }, [address]);

  const generateSignedMsg = () => {};

  const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage({
    async onSuccess(data, variables) {
      console.log("Success", data, variables.message);

      const reqBody = {
        address: address,
        unstake: {
          pets: getStakedData(petsData, selectedPets),
          cats: getStakedData(catsData, selectedCats),
          cooltopia: getStakedData(cooltopiaData, selectedCooltopia),
          wolf: getStakedData(wolvesData, selectedWolves),
        },
        stake: {
          pets: getUnstakedData(petsData, selectedPets),
          cats: getUnstakedData(catsData, selectedCats),
          cooltopia: getUnstakedData(cooltopiaData, selectedCooltopia),
          wolf: getUnstakedData(wolvesData, selectedWolves),
        },
        answer,
        path,
        signature: data,
        journeyScore,
        coolScore,
        message: variables.message,
      };

      console.log({ reqBody });
      try {
        const resp = await stakeTokens(reqBody, upcomingJourney);

        if (resp.ERROR) {
          notification.error({ message: resp.ERROR });
          return;
        }

        notification.success({ message: "Successfully Staked!" });
        if (
          reqBody.stake.cats.length > 0 ||
          reqBody.stake.pets.length > 0 ||
          reqBody.stake.wolf.length > 0
        ) {
          setIsWarningModalOpen(true);
        }
      } catch (error) {
        notification.error({ message: "Error while staking!" });
        console.log({ error });
      } finally {
        resetData();
        if (address && activeJourney) fetchData(activeJourney, address);
        setIsStaking(false);
        setIsConfirmationModalOpen(false);
        setIsStakeModalOpen(false);
        hasJoinedJourney(activeJourney, address).then((response) => {
          setJourneyParticipated(response?.result === true);
        });
      }
    },
    onError(error) {
      notification.error({ message: error.message });
      setIsStaking(false);
    },
  });
  const {
    data: rewardData,
    isError: rewardIsError,
    isLoading: rewardIsLoading,
    isSuccess: rewardIsSuccess,
    signMessage: rewardSignMessage,
  } = useSignMessage({
    async onSuccess(data, variables) {
      console.log("Success", data, variables.message);
      setTxnHash(data);
    },
    onError(error) {
      notification.error({ message: error.message });
      setIsStaking(false);
    },
  });

  useEffect(() => {
    if (txnHash) {
      const initiateEndJourney = async () => {
        const reqBody = {
          address: address,
          signature: txnHash,
          journey: incompleteJourney,
        };
        try {
          const resp = await endJourney(reqBody);

          if (resp.ERROR) {
            notification.error({ message: resp.ERROR });
            return;
          }

          notification.success({ message: "Successfully Ended!" });
        } catch (error) {
          notification.error({ message: "Error while ending!" });
          console.log({ error });
        } finally {
          resetData();
          setIsStaking(false);
          setIsCompleteModalOpen(false);
          setIsRewardModalOpen(true);
        }
      };
      initiateEndJourney();
    }
  }, [txnHash]);

  const { isWebBrowser } = useScreenDetector();
  const [visible, setVisible] = useState(true);

  //Popover
  const [journeyPopoverOpen, setJourneyPopoverOpen] = useState(-1);

  const handleJourneyPopoverOpenChange = (newOpen: number) => {
    if (newOpen === journeyPopoverOpen) {
      setJourneyPopoverOpen(-1);
    } else {
      setJourneyPopoverOpen(newOpen);
    }
  };

  //Connect Modal
  const { open } = useWeb3Modal();

  async function onOpen() {
    setIsLoading(true);
    await open();
    setIsLoading(false);
  }

  //Reward Data
  const [rewardFracture, setRewardFracture] = useState<any[]>([]);
  useEffect(() => {
    if (address) {
      fetchReward(address).then((response) => {
        setRewardFracture(response.data);
      });
    }
  }, [isRewardModalOpen, address]);

  return (
    <HomePageContainer>
      {/* <PauseOverlay /> */}
      {!isWebBrowser && visible ? (
        <Overlay closeOverlay={() => setVisible(false)}></Overlay>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {(isWebBrowser ||
              (!isWebBrowser &&
                !isModalOpen &&
                !isPuzzleModalOpen &&
                !isStakeModalOpen &&
                !isConfirmationModalOpen &&
                !selectionModal &&
                !isCompleteModalOpen &&
                !isRewardModalOpen)) && (
              <div className="sidebar-img-container relative">
                <div
                  className={`sidebar ${!isConnected ? "alignCenter" : ""} ${
                    !sidebarOpen ? "sidebar-close" : ""
                  } ${!isWebBrowser ? "mob-sidebar" : ""}`}
                >
                  {!isWebBrowser && (
                    <div className="sidebar-title">Journeys</div>
                  )}
                  {!isWebBrowser && showMaskMobile && (
                    <div className="mask-overlay-mb">
                      <button className="close-mask" onClick={() => setShowMaskMobile(false)}>&times;</button>
                      <div className="mask-mobile-content">
                        <h4>SEASON 2 COMPLETE</h4>
                        <p>Season rewards to be airdropped at the end of Shadow wolves Journey.</p>
                        <p>Cool Score rewards airdrop incoming.</p>
                        <p>Season 3 will begin after Sidekicks mint.</p>
                        <p>We. Like. The. Cats.</p>
                      </div>
                    </div>
                  )}
                  {isWebBrowser && (
                    <div
                      className="sidebar-button"
                      onClick={() => {
                        setSidebarOpen((prev) => !prev);
                      }}
                    >
                      {sidebarOpen ? (
                        <ChevronLeftIcon className="sidebar-button-icon" />
                      ) : (
                        <ChevronRightIcon className="sidebar-button-icon" />
                      )}
                    </div>
                  )}
                  {isConnected ? (
                    <>
                      <div className="score-group">
                        <CoolScore score={coolScore} address={address} />
                        <JourneyScore score={journeyScore} address={address} />
                      </div>
                      {activeJourney && journeys[activeJourney - 1] && (
                        <JourneyCard
                          loading={loading}
                          setIsLoading={setIsLoading}
                          journey={journeys[activeJourney - 1]}
                          journeyParticipated={journeyParticipated}
                          journeyEnded={journeyEnded}
                          showModal={showModal}
                          setIsCompleteModalOpen={setIsCompleteModalOpen}
                          sidebar
                          activeJourney={activeJourney}
                        />
                      )}

                      <div
                        className="profile-group"
                        onClick={() =>
                          window.open(
                            `https://coolcatsnft.com/user/${address}`,
                            "_blank" // <- This is what makes it open in a new window.
                          )
                        }
                      >
                        <img src={HOMEPAGE.USERICON} />
                        <span className="profile-text">Your Profile</span>
                      </div>
                    </>
                  ) : isWebBrowser ? (
                    <>
                      <div className="sidebar-connect-message">
                        Please connect your wallet to continue on your Journey!
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="connect-btn">
                        <ConnectButton
                          label="Connect"
                          accountStatus="address"
                          showBalance={false}
                        />
                      </div>
                      {/* <div className="mob-sidebar-connect-message">
                        Blue and Chugs have been sucked into a fracture, who is
                        brave enough to follow him and help?
                      </div> */}
                    </>
                  )}
                </div>

                {isWebBrowser && (
                  <div className="mask-overlay">
                    <div className="mask-upper-content">
                      <h4>SEASON 2 COMPLETE</h4>
                      <p>Season rewards to be airdropped at the end of Shadow wolves Journey.</p>
                      <p>Cool Score rewards airdrop incoming.</p>
                      <p>Season 3 will begin after Sidekicks mint.</p>
                    </div>
                    <div className="mask-lower-content">
                      <p>We. Like. The. Cats.</p>
                    </div>
                  </div>
                )}
                
                
                {isWebBrowser && (
                  <MapComponent
                    upcomingJourney={upcomingJourney}
                    activeJourney={activeJourney}
                    loading={loading}
                    setIsLoading={setIsLoading}
                    journeyParticipated={journeyParticipated}
                    journeyEnded={journeyEnded}
                    showModal={showModal}
                    setIsCompleteModalOpen={setIsCompleteModalOpen}
                    handleJourneyPopoverOpenChange={
                      handleJourneyPopoverOpenChange
                    }
                    journeyPopoverOpen={journeyPopoverOpen}
                    journeys={journeys}
                  />
                )}
              </div>
            )}

            {/* Mobile design for Join Modal 1 */}
            {!isWebBrowser && isModalOpen && (
              <div className="sidebar-img-container">
                <div className="sidebar mob-sidebar">
                  <div className="sidebar-title">Journeys</div>
                  <div className="score-group">
                    <CoolScore score={coolScore} address={address} />
                    <JourneyScore score={journeyScore} address={address} />
                  </div>
                  {/* <div className="mob-sidebar-connect-message align-center">
                    Meanwhile in Ardi's Shop...
                  </div> */}
                  {activeJourney &&
                    journeys[activeJourney - 1]?.contentCopy.map(
                      (copy: string, index: number, array: string[]) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="mob-sidebar-connect-message align-center"
                            >
                              {copy}
                            </div>
                          </>
                        );
                      }
                    )}

                  <div>
                    {activeJourney &&
                      (journeys[activeJourney - 1]?.mediaType === "image" ? (
                        <Zoom>
                          <img
                            src={journeys[activeJourney - 1]?.links[0]}
                            width="100%"
                          />
                        </Zoom>
                      ) : journeys[activeJourney - 1]?.mediaType ===
                        "carousel" ? (
                        <div style={{ position: "relative", width: "75%", margin: "auto" }}>
                          {activeJourney && (
                            <Carousel
                              renderArrowNext={(
                                onClickHandler,
                                hasNext,
                                label
                              ) =>
                                hasNext && (
                                  <IconButton
                                    onClick={onClickHandler}
                                    sx={{
                                      backgroundColor: "#231f20",
                                      color: "#ffffff",
                                      borderRadius: "20px",
                                      position: "absolute",
                                      zIndex: 1,
                                      top: "50%",
                                      right: "0px",
                                      opacity: 0.7,
                                      transition: "opacity 0.3s",
                                      "&:hover": {
                                        backgroundColor: "#231f20",
                                        color: "#ffffff",
                                        opacity: 1,
                                      },
                                    }}
                                  >
                                    <ChevronRightIcon />
                                  </IconButton>
                                )
                              }
                              renderArrowPrev={(
                                onClickHandler,
                                hasPrev,
                                label
                              ) =>
                                hasPrev && (
                                  <IconButton
                                    onClick={onClickHandler}
                                    sx={{
                                      backgroundColor: "#231f20",
                                      color: "#ffffff",
                                      borderRadius: "20px",
                                      position: "absolute",
                                      zIndex: 1,
                                      top: "50%",
                                      left: "0px",
                                      opacity: 0.7,
                                      transition: "opacity 0.3s",
                                      "&:hover": {
                                        backgroundColor: "#231f20",
                                        color: "#ffffff",
                                        opacity: 1,
                                      },
                                    }}
                                  >
                                    <ChevronLeftIcon />
                                  </IconButton>
                                )
                              }
                              onChange={(index) => {
                                if (
                                  index ===
                                  journeys[activeJourney - 1]?.links.length - 1
                                ) {
                                  setDisableStakeContinueButton(false);
                                }
                              }}
                            >
                              {journeys[activeJourney - 1]?.links.map(
                                (link: string) => {
                                  return (
                                    <Zoom>
                                      <img src={link} />
                                    </Zoom>
                                  );
                                }
                              )}
                            </Carousel>
                          )}
                        </div>
                      ) : (
                        isModalOpen && (
                          <iframe
                            width="400"
                            height="275"
                            src={`${journeys[activeJourney - 1]?.link}`}
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        )
                      ))}
                  </div>
                  <div className="journey-btn-group">
                    <div
                      className="journey-btn btn-cancel btn-slice-left"
                      onClick={handleCancel}
                    >
                      Back
                    </div>

                    <button
                      className="journey-btn btn-slice-right"
                      onClick={handlePuzzleModal}
                      // onClick={handleStakeModal}
                      disabled={(activeJourney && journeys[activeJourney - 1]?.mediaType === "carousel") ? disableStakeContinueButton: false}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* Mobile design for Puzzle Modal */}
            {!isWebBrowser && isPuzzleModalOpen && (
              <div className="sidebar-img-container">
                <div className="sidebar mob-sidebar">
                  <div className="sidebar-title">Journeys</div>
                  <div className="score-group">
                    <CoolScore score={coolScore} address={address} />
                    <JourneyScore score={journeyScore} address={address} />
                  </div>
                  {activeJourney &&
                    journeys[activeJourney - 1]?.puzzleCopy.map(
                      (copy: string, index: number, array: string[]) => {
                        return (
                          <div key={index}>
                            <div className="mob-sidebar-connect-message align-center">
                              {copy}
                            </div>
                            {index !== array.length - 1 && <br />}
                          </div>
                        );
                      }
                    )}
                  {/* <div className="mob-sidebar-connect-message align-center">
                    Can you find the 3 missing items from Ardi's workshop?
                  </div> */}
                  {/* <DeskPuzzle /> */}
                  {/* <DragPuzzle setOpenPuzzleAnswer={setOpenPuzzleAnswer}/> */}
                  {/* <SlidePuzzle setOpenPuzzleAnswer={setOpenPuzzleAnswer}/> */}
                  <div>
                    {activeJourney &&
                      (journeys[activeJourney - 1]?.mediaType2 === "image" ? (
                        <Zoom>
                          <img
                            src={journeys[activeJourney - 1]?.link2}
                            width="300px"
                          />
                        </Zoom>
                      ) : (
                        isModalOpen && (
                          <iframe
                            width="400"
                            height="275"
                            src={`${journeys[activeJourney - 1]?.link2}`}
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        )
                      ))}
                  </div>
                  {/* <div
                    className="print-link"
                    onClick={() =>
                      window.open(
                        "https://content.coolcatsnft.com/wp-content/uploads/2023/07/Journey17Puzzle_PrinterFriendly.pdf",
                        "_blank" // <- This is what makes it open in a new window.
                      )
                    }
                  >
                    Printer Friendly Version
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="puzzle-note" style={{ color: "#ffffff" }}>
                      Need Help? Join our{" "}
                      <span
                        className="print-link"
                        onClick={() =>
                          window.open(
                            "https://discord.com/invite/coolcatsnft",
                            "_blank" // <- This is what makes it open in a new window.
                          )
                        }
                      >
                        DISCORD
                      </span>{" "}
                      and ask!
                    </div>
                    {/* <div
                      className="puzzle-note"
                      style={{ color: "#ffffff", display: "block" }}
                    >
                      Hint: Color coding important symbols can be helpful
                      <span
                          className="print-link"
                          onClick={() =>
                            window.open(
                              "https://world.coolcatsnft.com/cooltopia-the-world/contents",
                              "_blank" // <- This is what makes it open in a new window.
                            )
                          }
                        >
                          LORE
                        </span>
                    </div> */}
                  </div>
                  <div className="journey-btn-group">
                    <div
                      className="journey-btn btn-cancel btn-slice-left"
                      onClick={handlePuzzleModalBack}
                    >
                      Back
                    </div>

                    <button
                      className="journey-btn btn-slice-right"
                      onClick={handleStakeModal}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Mobile design for Join Modal 2 */}
            {!isWebBrowser &&
              isStakeModalOpen &&
              !isConfirmationModalOpen &&
              !selectionModal && (
                <div className="sidebar-img-container">
                  <div className="sidebar mob-sidebar paddingNone">
                    <div className="sidebar-title">Journeys</div>
                    <div className="score-group">
                      <CoolScore score={coolScore} address={address} />
                      <JourneyScore score={journeyScore} address={address} />
                    </div>
                    {/* <div className="mob-sidebar-connect-message align-center">
                      The community chose Old Man Berg.. Let's see how that
                      works out.
                    </div> */}
                    <div className="journey-input-group mob-journey-input-group">
                      <div className="journey-input-text">
                        {activeJourney &&
                          `${journeys[activeJourney - 1]?.question}`}
                      </div>
                      <input
                        type="text"
                        className="journey-input"
                        placeholder="Answer..."
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                      />
                      <p className="journey-info-text2">
                        GET IT RIGHT AND You’ll gain +300 JOURNEY Bonus
                      </p>
                      <p
                        className="journey-info-text2 journey-info-text3"
                        style={{ justifyContent: "center" }}
                      >
                        {activeJourney &&
                          `${journeys[activeJourney - 1]?.pathQuestion}`}
                      </p>
                      {activeJourney && (
                        <div className="modal-journey-btn-group">
                          <div
                            className={`journey-btn left-btn ${
                              path === journeys[activeJourney - 1]?.leftButton
                                ? "active"
                                : "inactive"
                            }`}
                            onClick={() =>
                              setPath((prev: any) =>
                                prev === journeys[activeJourney - 1]?.leftButton
                                  ? ""
                                  : journeys[activeJourney - 1]?.leftButton
                              )
                            }
                          >
                            {journeys[activeJourney - 1]?.leftButton}
                          </div>
                          {/* <div
                          className={`journey-btn middle-btn ${
                            path === "Not Really" ? "active" : "inactive"
                          }`}
                          onClick={() =>
                            setPath((prev: any) =>
                              prev === "Not Really" ? "" : "Not Really"
                            )
                          }
                        >
                          Not Really
                        </div>
                        <div
                          className={`journey-btn middle-btn ${
                            path === "Kind Of" ? "active" : "inactive"
                          }`}
                          onClick={() =>
                            setPath((prev: any) =>
                              prev === "Kind Of" ? "" : "Kind Of"
                            )
                          }
                        >
                          Kind Of
                        </div> */}
                          <div
                            className={`journey-btn right-btn ${
                              path === journeys[activeJourney - 1]?.rightButton
                                ? "active"
                                : "inactive"
                            }`}
                            onClick={() =>
                              setPath((prev: any) =>
                                prev ===
                                journeys[activeJourney - 1]?.rightButton
                                  ? ""
                                  : journeys[activeJourney - 1]?.rightButton
                              )
                            }
                          >
                            {journeys[activeJourney - 1]?.rightButton}
                          </div>
                        </div>
                      )}
                      <p className="journey-helper-text">
                        Select one to continue{" "}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "20px",
                        background: "#231F20",
                        width: "100%",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                      }}
                    >
                      <div
                        className="journey-btn btn-outline btn-wallet"
                        onClick={
                          activeJourney &&
                          journeys[activeJourney - 1]?.type === "shadow"
                            ? selectAllWolves
                            : selectAll
                        }
                      >
                        Select All
                      </div>
                      {activeJourney &&
                      journeys[activeJourney - 1]?.type === "shadow" ? (
                        <div className="journey-stake-group mob-journey-stake-group">
                          <div className="journey-stake mob-journey-stake">
                            <div className="image-container">
                              <img
                                src={HOMEPAGE.NFTPLACEHOLDER}
                                width="210px"
                                onClick={() =>
                                  setSelectionModal(TokenType.Wolves)
                                }
                                alt={TokenType.Wolves}
                              />
                              <div className="badge">
                                {getCount(wolvesData, selectedWolves)}
                              </div>
                              {getCount(wolvesData, selectedWolves) > 0 && (
                                <img
                                  src={`https://avatar.coolcatsnft.com/shadowwolf/${
                                    getRandom(selectedWolves) ||
                                    wolvesData[0]?.id
                                  }.png`}
                                  width="185px"
                                  height={"229px"}
                                  style={{
                                    position: "absolute",
                                    left: "12.5px",
                                    top: "15px",
                                    objectFit: "cover",
                                    borderRadius: "16px",
                                  }}
                                  onClick={() =>
                                    setSelectionModal(TokenType.Wolves)
                                  }
                                  alt={TokenType.Wolves}
                                />
                              )}
                            </div>
                            <div className="stake-text mob-stake-text">
                              Shadow Wolves
                              <br></br>
                              <div className="stake-helper-text mob-stake-helper-text">
                                +75 FIRST WOLF<br></br>10% LESS EACH ADDITIONAL
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="journey-stake-group mob-journey-stake-group">
                          <div className="journey-stake mob-journey-stake">
                            <div className="image-container">
                              <img
                                src={HOMEPAGE.NFTPLACEHOLDER}
                                width="210px"
                                onClick={() =>
                                  setSelectionModal(TokenType.Cats)
                                }
                                alt={TokenType.Cats}
                              />
                              <div className="badge">
                                {getCount(catsData, selectedCats)}
                              </div>
                              {getCount(catsData, selectedCats) > 0 && (
                                <img
                                  src={`https://avatar.coolcatsnft.com/cat/${
                                    getRandom(selectedCats) || catsData[0]?.id
                                  }.png`}
                                  width="185px"
                                  height={"229px"}
                                  style={{
                                    position: "absolute",
                                    left: "12.5px",
                                    top: "15px",
                                    objectFit: "cover",
                                    borderRadius: "16px",
                                  }}
                                  onClick={() =>
                                    setSelectionModal(TokenType.Cats)
                                  }
                                  alt={TokenType.Cats}
                                />
                              )}
                            </div>
                            <div className="stake-text mob-stake-text">
                              COOL CATS
                              <br></br>
                              <div className="stake-helper-text mob-stake-helper-text">
                                +100 PER CAT<br></br>5 MAX
                              </div>
                            </div>
                          </div>
                          <div className="journey-stake mob-journey-stake">
                            <div className="image-container">
                              <img
                                src={HOMEPAGE.NFTPLACEHOLDER}
                                width="175px"
                                onClick={() =>
                                  setSelectionModal(TokenType.Pets)
                                }
                                alt={TokenType.Pets}
                              />
                              <div className="badge">
                                {getCount(petsData, selectedPets)}
                              </div>
                              {getCount(petsData, selectedPets) > 0 && (
                                <img
                                  src={`https://avatar.coolcatsnft.com/pet/${
                                    getRandom(selectedPets) || petsData[0]?.id
                                  }.png`}
                                  width="150px"
                                  height={"189px"}
                                  style={{
                                    position: "absolute",
                                    left: "12.5px",
                                    top: "15px",
                                    objectFit: "cover",
                                    borderRadius: "16px",
                                  }}
                                  onClick={() =>
                                    setSelectionModal(TokenType.Pets)
                                  }
                                  alt={TokenType.Pets}
                                />
                              )}
                            </div>
                            <div className="stake-text mob-stake-text">
                              COOL PETS
                              <br></br>
                              <div className="stake-helper-text mob-stake-helper-text">
                                +50 PER PET<br></br>6 MAX
                              </div>
                            </div>
                          </div>
                          <div
                            className="journey-stake mob-journey-stake"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <div className="image-container">
                              <img
                                src={HOMEPAGE.NFTPLACEHOLDER}
                                width="175px"
                                height="140px"
                                onClick={() =>
                                  setSelectionModal(TokenType.Cooltopia)
                                }
                                alt={TokenType.Cooltopia}
                              />
                              <div className="badge" style={{ right: 30 }}>
                                {getCount(cooltopiaData, selectedCooltopia)}
                              </div>
                              {getCount(cooltopiaData, selectedCooltopia) >
                                0 && (
                                <img
                                  // src={`https://avatar.coolcatsnft.com/cooltopia/${
                                  //   selectedCooltopia[0] || cooltopiaData[0]?.id
                                  // }.png`}
                                  src={`https://metadata.coolcatsnft.com/item/image/${Math.floor(
                                    (selectedCooltopia[0] ||
                                      cooltopiaData[0]?.id) / 1000
                                  )}${
                                    Math.floor(
                                      (selectedCooltopia[0] ||
                                        cooltopiaData[0]?.id) / 1000
                                    ) === 77
                                      ? ".png"
                                      : ".gif"
                                  }`}
                                  width="95px"
                                  height="120px"
                                  style={{
                                    position: "absolute",
                                    left: "40.5px",
                                    top: "11px",
                                    objectFit: "cover",
                                    borderRadius: "16px",
                                  }}
                                  onClick={() =>
                                    setSelectionModal(TokenType.Cooltopia)
                                  }
                                  alt={TokenType.Cooltopia}
                                />
                              )}
                            </div>
                            <div className="stake-text mob-stake-text">
                              Kritten Boss
                              <br></br>
                              <div
                                className="stake-helper-text mob-stake-helper-text"
                                // style={{ visibility: "hidden" }}
                              >
                                +300-500<br></br>1 MAX
                              </div>
                            </div>
                          </div>
                          {/* <div className="journey-stake mob-journey-stake">
                          <div className="image-container">
                            <img
                              src={HOMEPAGE.NFTPLACEHOLDER}
                              width="175px"
                              onClick={() =>
                                setSelectionModal(TokenType.Fractures)
                              }
                              alt={TokenType.Fractures}
                            />
                            <div className="badge">
                              {getCount(fracturesData, selectedFractures)}
                            </div>
                            {getCount(fracturesData, selectedFractures) > 0 && (
                              <img
                                src={`https://avatar.coolcatsnft.com/fracture/${
                                  selectedFractures[0] || fracturesData[0]?.id
                                }.png`}
                                width="150px"
                                height={"189px"}
                                style={{
                                  position: "absolute",
                                  left: "12.5px",
                                  top: "15px",
                                  objectFit: "cover",
                                  borderRadius: "16px",
                                }}
                                onClick={() =>
                                  setSelectionModal(TokenType.Fractures)
                                }
                                alt={TokenType.Fractures}
                              />
                            )}
                          </div>
                          <div className="stake-text mob-stake-text">
                            FRACTURES*
                          </div>
                        </div>
                        <div className="mob-helper-footnote">
                          *Applied Journey Score goes down after the first
                          fracture
                        </div> */}
                        </div>
                      )}
                    </div>

                    <div className="journey-btn-group">
                      <div
                        className="journey-btn btn-cancel btn-slice-left"
                        onClick={handleStakeModalBack}
                      >
                        Back
                      </div>

                      <button
                        className="journey-btn btn-slice-right"
                        onClick={() => {
                          setIsConfirmationModalOpen(true);
                        }}
                        disabled={
                          path === "" || path === null || path === undefined
                        }
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              )}
            {/* Mobile design for Confirm Modal */}
            {!isWebBrowser && isConfirmationModalOpen && (
              <div className="sidebar-img-container">
                <div className="sidebar mob-sidebar paddingNone">
                  <div className="sidebar-title">Journeys</div>
                  <div className="score-group">
                    <CoolScore score={coolScore} address={address} />
                    <JourneyScore score={journeyScore} address={address} />
                  </div>
                  <div className="mob-confirm-header">CONFIRM STAKING</div>
                  <div className="mob-confirm-description">
                    Signing the message below confirms soft-staking of the
                    assets previously selected.
                  </div>
                  <div className="journey-confirm-btn-group">
                    <div className="mob-confirm-description">
                      I understand that if the assets are sold or transferred
                      prior to the end of the Journey, I will no longer be
                      eligible for Journey Rewards.
                    </div>
                    <button
                      className="journey-btn mob-confirm-journey-btn"
                      onClick={async () => {
                        setIsStaking(true);

                        signMessage({
                          message: `${
                            activeJourney &&
                            journeys[activeJourney - 1].joinSignMessage
                          }`,
                        });
                      }}
                      disabled={isStaking || false}
                    >
                      Sign
                    </button>
                  </div>

                  <div className="mob-confirm-warning">
                    Note: Signature does not remove or transfer assets from your
                    wallet and will not cost any gas to complete.
                  </div>
                </div>
              </div>
            )}
            {/* Mobile design for End Modal 1 */}
            {!isWebBrowser && isCompleteModalOpen && (
              <div className="sidebar-img-container">
                <div className="sidebar mob-sidebar paddingNone">
                  <div className="sidebar-title">Journeys</div>
                  <div className="score-group">
                    <CoolScore score={coolScore} address={address} />
                    <JourneyScore score={journeyScore} address={address} />
                  </div>
                  <div className="mob-confirm-header">JOURNEY COMPLETE</div>
                  <div className="mob-confirm-description">
                    {incompleteJourney &&
                      `${journeys[incompleteJourney - 1]?.completeMessage}`}
                    <br />
                    <br />
                    <em>"Let’s see what we’ve received for trusting him…"</em>
                  </div>
                  <div className="journey-confirm-btn-group">
                    <div className="mob-confirm-description">
                      Signing this message completes your journey and collects
                      your rewards.
                    </div>
                    <button
                      className="journey-btn mob-confirm-journey-btn"
                      onClick={async () => {
                        setIsStaking(true);

                        rewardSignMessage({
                          message: `${
                            incompleteJourney &&
                            journeys[incompleteJourney - 1].rewardSignMessage
                          }`,
                        });
                      }}
                      disabled={isStaking || false}
                    >
                      Sign
                    </button>
                  </div>

                  <div className="mob-confirm-warning">
                    Note: Signature does not remove or transfer assets from your
                    wallet and will not cost any gas to complete.
                  </div>
                </div>
              </div>
            )}
            {/* Mobile design for Reward Modal */}
            {!isWebBrowser && isRewardModalOpen && (
              <RewardModal
                open={isRewardModalOpen}
                onCancel={(nextModal) => {
                  setIsRewardModalOpen(false);
                  setIncompleteJourney(null);
                  if (nextModal) {
                    setIsModalOpen(true);
                  }
                }}
                data={rewardFracture}
                isStaking={isStaking}
                isDisabled={false}
                mobile={true}
                journeyTitle={
                  incompleteJourney
                    ? `Journey #${journeys[incompleteJourney - 1]?.number}: ${
                        journeys[incompleteJourney - 1]?.title
                      }`
                    : ""
                }
                hasMinted={hasMinted}
                setHasMinted={setHasMinted}
                isShadow={
                  incompleteJourney
                    ? journeys[incompleteJourney - 1]?.type === "shadow"
                    : false
                }
              />
            )}
            {/* Mobile design for NFT Selection Modal */}
            {!isWebBrowser && selectionModal && (
              <div className="sidebar-img-container">
                <div className="sidebar mob-sidebar paddingNone">
                  <div className="sidebar-title">Journeys</div>
                  <div className="score-group">
                    <CoolScore score={coolScore} address={address} />
                    <JourneyScore score={journeyScore} address={address} />
                  </div>
                  {selectionModal === TokenType.Cats && (
                    <StakeSelection
                      open={selectionModal === TokenType.Cats}
                      onCancel={() => setSelectionModal(null)}
                      selected={selectedCats}
                      setSelected={setSelectedCats}
                      data={catsData}
                      mobile={true}
                      type={TokenType.Cats}
                    />
                  )}
                  {selectionModal === TokenType.Pets && (
                    <StakeSelection
                      open={selectionModal === TokenType.Pets}
                      onCancel={() => setSelectionModal(null)}
                      selected={selectedPets}
                      setSelected={setSelectedPets}
                      data={petsData}
                      mobile={true}
                      type={TokenType.Pets}
                    />
                  )}
                  {selectionModal === TokenType.Cooltopia && (
                    <StakeSelection
                      open={selectionModal === TokenType.Cooltopia}
                      onCancel={() => setSelectionModal(null)}
                      selected={selectedCooltopia}
                      setSelected={setSelectedCooltopia}
                      data={cooltopiaData}
                      cooltopia
                      mobile={true}
                      type={TokenType.Cooltopia}
                    />
                  )}
                  {selectionModal === TokenType.Wolves && (
                    <StakeSelection
                      open={selectionModal === TokenType.Wolves}
                      onCancel={() => setSelectionModal(null)}
                      selected={selectedWolves}
                      setSelected={setSelectedWolves}
                      data={wolvesData}
                      mobile={true}
                      type={TokenType.Wolves}
                    />
                  )}
                </div>
              </div>
            )}
            {isWebBrowser && (
              <>
                <ContentModal
                  isModalOpen={isModalOpen}
                  handleCancel={handleCancel}
                  activeJourney={activeJourney}
                  handlePuzzleModal={handlePuzzleModal}
                  // handlePuzzleModal={handleStakeModal}
                  journeys={journeys}
                    setDisableStakeContinueButton={setDisableStakeContinueButton}
                    disableStakeContinueButton={disableStakeContinueButton}
                />
                <PuzzleModal
                  isPuzzleModalOpen={isPuzzleModalOpen}
                  handlePuzzleModalCancel={handlePuzzleModalCancel}
                  activeJourney={activeJourney}
                  handlePuzzleModalBack={handlePuzzleModalBack}
                  handleStakeModal={handleStakeModal}
                  journeys={journeys}
                  isModalOpen={isModalOpen}
                  setOpenPuzzleAnswer={setOpenPuzzleAnswer}
                />
                <StakeModal
                  isStakeModalOpen={isStakeModalOpen}
                  handleStakeModalCancel={handleStakeModalCancel}
                  activeJourney={activeJourney}
                  path={path}
                  setPath={setPath}
                  selectAll={
                    activeJourney &&
                    journeys[activeJourney - 1]?.type === "shadow"
                      ? selectAllWolves
                      : selectAll
                  }
                  setSelectionModal={setSelectionModal}
                  handleStakeModalBack={handleStakeModalBack}
                  setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                  address={address}
                  journeys={journeys}
                />

                <StakeSelection
                  open={selectionModal === TokenType.Cats}
                  onCancel={() => setSelectionModal(null)}
                  selected={selectedCats}
                  setSelected={setSelectedCats}
                  data={catsData}
                  type={TokenType.Cats}
                />
                <StakeSelection
                  open={selectionModal === TokenType.Pets}
                  onCancel={() => setSelectionModal(null)}
                  selected={selectedPets}
                  setSelected={setSelectedPets}
                  data={petsData}
                  type={TokenType.Pets}
                />
                <StakeSelection
                  open={selectionModal === TokenType.Cooltopia}
                  onCancel={() => setSelectionModal(null)}
                  selected={selectedCooltopia}
                  setSelected={setSelectedCooltopia}
                  data={cooltopiaData}
                  cooltopia
                  type={TokenType.Cooltopia}
                />
                <StakeSelection
                  open={selectionModal === TokenType.Wolves}
                  onCancel={() => setSelectionModal(null)}
                  selected={selectedWolves}
                  setSelected={setSelectedWolves}
                  data={wolvesData}
                  type={TokenType.Wolves}
                />

                <ConfirmModal
                  open={isConfirmationModalOpen}
                  activeJourney={activeJourney}
                  journeys={journeys}
                  onCancel={() => {
                    setIsConfirmationModalOpen(false);
                  }}
                  isStaking={isStaking}
                  isDisabled={false}
                  onSubmit={async () => {
                    setIsStaking(true);

                    signMessage({
                      message: `${
                        activeJourney &&
                        journeys[activeJourney - 1].joinSignMessage
                      }`,
                    });
                  }}
                  journeyTitle={
                    activeJourney
                      ? `Journey #${journeys[activeJourney - 1]?.number}: ${
                          journeys[activeJourney - 1]?.title
                        }`
                      : ""
                  }
                />

                <CompleteModal
                  open={isCompleteModalOpen}
                  onCancel={() => {
                    setIsCompleteModalOpen(false);
                  }}
                  setHasMinted={setHasMinted}
                  isStaking={isStaking}
                  isDisabled={false}
                  onSubmit={async () => {
                    setIsStaking(true);

                    rewardSignMessage({
                      message: `${
                        incompleteJourney &&
                        journeys[incompleteJourney - 1].rewardSignMessage
                      }`,
                    });
                  }}
                  journeyTitle={
                    incompleteJourney
                      ? `Journey #${journeys[incompleteJourney - 1]?.number}: ${
                          journeys[incompleteJourney - 1]?.title
                        }`
                      : ""
                  }
                  completeMessage={
                    incompleteJourney
                      ? journeys[incompleteJourney - 1]?.completeMessage
                      : ""
                  }
                  isWhitelisted={isWhitelisted}
                  setTxnHash={setTxnHash}
                  isShadow={
                    incompleteJourney
                      ? journeys[incompleteJourney - 1]?.type === "shadow"
                      : false
                  }
                />
                <RewardModal
                  open={isRewardModalOpen}
                  onCancel={(nextModal) => {
                    setIsRewardModalOpen(false);
                    setIncompleteJourney(null);
                    if (nextModal) {
                      setIsModalOpen(true);
                    }
                  }}
                  data={rewardFracture}
                  isStaking={isStaking}
                  isDisabled={false}
                  journeyTitle={
                    incompleteJourney
                      ? `Journey #${journeys[incompleteJourney - 1]?.number}: ${
                          journeys[incompleteJourney - 1]?.title
                        }`
                      : ""
                  }
                  hasMinted={hasMinted}
                  setHasMinted={setHasMinted}
                  isShadow={
                    incompleteJourney
                      ? journeys[incompleteJourney - 1]?.type === "shadow"
                      : false
                  }
                />

                <WarningModal
                  open={isWarningModalOpen}
                  onCancel={() => {
                    setIsWarningModalOpen(false);
                  }}
                />
                <AnswerModal
                  open={openPuzzleAnswer}
                  answer="You've earned 300 Journey Points!"
                  onCancel={() => {
                    setOpenPuzzleAnswer(false);
                  }}
                />
              </>
            )}
          </div>
        </>
      )}
    </HomePageContainer>
  );
}

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .imgContainer {
    position: relative;
    width: 100%;
  }

  .journey-info {
    width: 250px;
    height: 230px;
    padding: 30px;
    background: #231f20;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .journey-info-text {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 147.6%;
    /* or 30px */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
  }

  .journey-title {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #eb515d;
  }

  .journey-ends {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .journey-countdown {
    margin-top: 10px;
  }

  .journey-btn {
    background: #0094e3;
    border-radius: 73px;
    border: none;
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    padding: 10px 20px;
    padding-top: 15px;
    margin: auto;
    margin-top: 10px;
  }

  .joined-btn {
    background: #fad121;
    color: #231f20;
  }

  .modal-subtitle {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #87c7ec;
  }

  .modal-title {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 60px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #231f20;
  }

  .sidebar-img-container {
    display: flex;
    width: 100%;
  }

  .sidebar {
    width: 350px;
    background: #353233;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;
    padding-top: 40px;
    position: relative;
    margin-left: 0px;
    transition: margin-left 0.3s;
    z-index: 1;
  }

  .sidebar-journey-info {
    width: 275px;
    padding: 20px;
    background: #231f20;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  .score-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .profile-group {
    border: 2px solid #0094e3;
    border-radius: 73px;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    padding: 0px 20px;
    opacity: 0.8;
    transition: opacity 0.3s;
  }

  .profile-group:hover {
    opacity: 1;
  }

  .profile-text {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #0094e3;
    padding-top: 5px;
  }

  .sidebar-connect-message {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132.1%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .alignCenter {
    justify-content: center;
  }

  .sidebar-button {
    background: #2e2b2c;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: absolute;
    right: -20px;
    z-index: 1;
    cursor: pointer;
  }

  .sidebar-button-icon {
    color: white;
  }

  .sidebar-close {
    margin-left: -350px;
  }

  .mob-sidebar {
    width: 100%;
    padding-bottom: 70px;
  }

  .quest-container {
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }

  .marker {
    width: 80px;
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    // transition: 0.2s ease-in;
    // transform-origin: bottom;
    // :hover {
    //   transform: scale(1.3);
    // }
  }

  .lock-icon {
    width: 65px;
    height: 65px;
  }

  .quest-number {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 147.6%;
    /* or 53px */

    color: #ffffff;

    -webkit-text-stroke: 3px black;
    padding-top: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .btn-wallet {
    color: #fad121;
    border: 1px solid #fad121;
    background-color: transparent;
    font-weight: bold;
    width: 160px;
    height: 44px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding-top: 10px;
  }

  .btn-wallet:hover {
    color: #fad121;
    border: 1px solid #fad121;
  }

  .sidebar-title {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .mob-sidebar-connect-message {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150.4%;
    /* or 24px */

    display: flex;
    align-items: center;

    color: #ffffff;
    padding: 0px 10px;
  }

  .align-center {
    text-align: center;
  }

  .journey-btn-group {
    display: flex;
    gap: 5px;
    position: relative;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .btn-cancel {
    background-color: #eb515d;
    color: #ffffff;
  }

  .btn-slice-left {
    border-radius: 0 10px 0 0;
    width: 150px;
    margin: initial;
  }

  .btn-slice-right {
    border-radius: 10px 0 0 0;
    background: #0094e3;
    width: 150px;
    color: #ffffff;
    border: none;
    margin: initial;
  }

  .mob-journey-input-group {
    background: transparent;
  }

  .mob-journey-stake-group {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .paddingNone {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mob-stake-text,
  .mob-stake-helper-text {
    color: #ffffff;
  }

  .mob-helper-footnote {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 159.4%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #6f6f6f;
    margin-top: 30px;
  }

  .mob-confirm-header {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .mob-confirm-description {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #ffffff;
  }

  .mob-confirm-warning {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 159.4%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #6f6f6f;
  }

  .mob-confirm-journey-btn {
    margin-top: 30px;
  }

  .path1 {
    width: 150px;
    border: 4px dashed #fa2121;
    transform: rotate(-140.88deg);
    position: absolute;
    top: 47%;
    right: 10%;
  }

  .mob-journey-stake {
    justify-content: flex-start;
  }

  .left-btn {
    border-radius: 73px 0px 0px 73px;
    background: #fad121;
    color: #231f20;
  }

  .right-btn {
    border-radius: 0px 73px 73px 0px;
    background: #fad121;
    color: #231f20;
  }

  .middle-btn {
    border-radius: 0px;
    background: #fad121;
    color: #231f20;
  }

  .pause-overlay-container {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.81);
    z-index: 100;
    display: flex;
  }

  .footer-text > .subtitle {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 55px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #fad121;
  }

  .footer-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    margin: auto;
  }

  @media (max-width: 768px) {
    .footer-text {
      width: 100%;
    }

    .footer-text > .subtitle {
      font-size: 18px;
      line-height: 1;
    }
  }

  .footer-text > .title {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 900;
    font-size: 74px;
    line-height: 66px;
    /* or 83px */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    .footer-text > .title {
      font-size: 48px;
      line-height: 48px;
      margin-top: 8px;
    }
  }

  .overlay-text-container {
    background: #231f20;
    border-radius: 20px;
    padding: 20px;
    margin-top: 10px;
    text-align: center;

    font-family: "Herokid";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 27px;
    text-align: center;

    color: #ffffff;
  }

  .overlay-text-container > span {
    font-weight: 600;
  }

  .icon-box {
    position: absolute;
  }

  .mask-overlay {
    position: absolute;
    width: calc(100% - 288px);
    background: rgba(5, 5, 5, 0.88);
    top: 0;
    left: 288px;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 17px 60px;

    .mask-upper-content {
      padding-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 70%;
      h4 {
        color: #EDEDED;
        text-align: center;
        font-family: "Herokid";
        font-size: 34px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
      p {
        color: #FFF;
        text-align: center;
        font-family: "Herokid";
        font-size: 18px;
        font-style: normal;
        font-weight: 310;
        line-height: normal;
      }
    }

    .mask-lower-content {
      p {
        color: #FFF;
        font-family:"Herokid";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  .mask-overlay-mb {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(5, 5, 5, 0.88);
    top: 0;
    left: 0;
    z-index: 1;
    display: flex:
    align-items: center;
    justify-content: center;
    padding: 20px;

    .close-mask {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 32px;
      color: #fff;
      background: none;
      outline: none;
      border: none;
      font-weight: 600;
    }

    .mask-mobile-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-top: 30px;

      h4 {
        color: #FFF;
        text-align: center;
        font-family: Herokid;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      p {
        color: #FFF;
        font-family: Herokid;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.064px;
        text-align: center;

        &:last-child {
          font-weight: 800;
        }
      }
    }
  }
`;
