import { HOMEPAGE, JOURNEY6, DESKPUZZLE, JOURNEY7, JOURNEY8, JOURNEY9, JOURNEY10, JOURNEY11, JOURNEY12, JOURNEY13, JOURNEY14, JOURNEY15, JOURNEY16, JOURNEY17, JOURNEY18 } from "../constants/images";

const journeys = [
    {
      number: 1,
        title: "The Masked Village",
        type: "normal",
      startDate: "2.09.2023 15:00:00",
      stakingCloseDate: "2.13.2023 17:00:00",
      endDate: "2.23.2023 15:00:00",
      markerPosition: {
        top: 38.3,
        right: 14.4,
      },
      joinSignMessage: "...",
      rewardSignMessage: "It appears the community chose LEFT - I am not sure that was the RIGHT choice",
      completeMessage: "You were brave allowing this fracture to rip your body into a million pieces. While your screaming wasn’t appreciated, we’re glad you’re here to help.",
      question: "...",
      pathQuestion: "...",
      mediaType: "video",
    link: "https://www.youtube.com/embed/BRzlKlCgYgo",
        links:[],
        reward: {
            selectionHash: "0x4e22fd59634520f0c1044fbbf6045edb965f0cee",
            selectionLink: "https://polygonscan.com/tx/0x34aea9320dfdeea1972f0d9d4e7d52091b05567c3fef2339899a11b0b2bf27b4#eventlog",
            data: [
                {
                    token_id: "4701",
                    winner: "0x1dc9dfabe4bb9150d2eece81a09b80ea8589bc5a",
                    token_type: "CAT",
                },
                {
                    token_id: "11484",
                    winner: "0x30d5ec8ccada4d794152ece025c95f55d0cd2e46",
                    token_type: "FRAC",
                },
                {
                    token_id: "11485",
                    winner: "0xcc4672b681eed7977e7ed5bcc7846b6636b86fd4",
                    token_type: "FRAC",
                },
                {
                    token_id: "11486",
                    winner: "0xc6b37e3715261f096c81ac60507f30e1f70757c7",
                    token_type: "FRAC",
                },
                {
                    token_id: "11487",
                    winner: "0xff0af337e8e4c2c2e4118dfd099e1406d1b023b4",
                    token_type: "FRAC",
                },
                {
                    token_id: "11488",
                    winner: "0x0bebc115818fc49447db8f043258d7aac9b5fbe2",
                    token_type: "FRAC",
                },
                {
                    token_id: "11489",
                    winner: "0x4699c7f00a30689fd6986382785ef98d28c4bc74",
                    token_type: "FRAC",
                },
                {
                    token_id: "11490",
                    winner: "0xd595f140c2575d3e944332ea0b89f3ca08251704",
                    token_type: "FRAC",
                },
                {
                    token_id: "11491",
                    winner: "0xd4bff2b92a51355d6b2b3f6c399aab5c87314e66",
                    token_type: "FRAC",
                },
                {
                    token_id: "11492",
                    winner: "0x6f2adc5a75e69c03d6acee4b7df84ae042d028f5",
                    token_type: "FRAC",
                },
                {
                    token_id: "11493",
                    winner: "0x57146999b5b8504a798bb083d2bdfce16a45a9cb",
                    token_type: "FRAC",
                },
            ]
        }
    },
    {
      number: 2,
        title: "Stealthy Investigation!",
        type: "normal",
      startDate: "2.23.2023 15:00:00",
      stakingCloseDate: "2.27.2023 15:00:00",
      endDate: "3.09.2023 00:00:00",
      markerPosition: {
        top: 49.3,
        right: 8.4,
      },
      joinSignMessage: "I REALLY HOPE I MADE THE RIGHT CHOICE...",
      rewardSignMessage: "It appears the community chose LEFT - I am not sure that was the RIGHT choice",
      question: "Find the hidden message (hint: 3 words):",
      pathQuestion:
        "It looks like Blue and Chugs are being chased! Which way should they go, left into the thick dense forest or right into a well tread path among the trees?",
        completeMessage: "I am not sure bringing you here was the best idea.. ",
      mediaType: "image",
      link: HOMEPAGE.JOURNEY2COMIC,
      links:[],
        reward: {
          selectionHash: "0x4e22fd59634520f0c1044fbbf6045edb965f0cee",
          selectionLink: "https://polygonscan.com/tx/0x2de699af3fd1f4606de5533ee0a915a9a965c17fa0d94dcc79f045740b89aa46#eventlog",
          data: [
              {
                  token_id: "4863",
                  winner: "0xa04f5214df984db6601d372efa0c135853efe387",
                  token_type: "CAT",
              },
              {
                  token_id: "11518",
                  winner: "0x70817f848cc79acb114f606685e8751943fb02c2",
                  token_type: "FRAC",
              },
              {
                  token_id: "11519",
                  winner: "0x21e3f71371e26e291441d7e5ecf7d2ef5789bcdc",
                  token_type: "FRAC",
              },
              {
                  token_id: "11520",
                  winner: "0xea31c22f355865c791f4f1f0f2fa1d9552973718",
                  token_type: "FRAC",
              },
              {
                  token_id: "11523",
                  winner: "0x1e86218cf301d95b98dac9bd8ebfd5a0b0a7d4ef",
                  token_type: "FRAC",
              },
              {
                  token_id: "11524",
                  winner: "0xe3a9580e3a9ffa6233fbea2d6e6af377f06496bb",
                  token_type: "FRAC",
              },
              {
                  token_id: "11525",
                  winner: "0xe3018e21c82e769b3a53f6157ff2ac62691259c8",
                  token_type: "FRAC",
              },
              {
                  token_id: "11526",
                  winner: "0xafc8ed7a1b59f50af3029400a2ea199db42ef506",
                  token_type: "FRAC",
              },
              
          ]
      }
    
    },
    {
      number: 3,
        title: "The Wrong Path",
        type: "normal",
      startDate: "3.09.2023 15:00:00",
      stakingCloseDate: "3.13.2023 15:00:00",
      endDate: "3.23.2023 00:00:00",
      markerPosition: {
        top: 52.3,
        right: 15.4,
      },
      joinSignMessage: "Well, that escalated quickly...",
      rewardSignMessage: "The community finally made the right choice. #teamchugs",
      question: "Puzzle Answer:",
      pathQuestion: "Are you...",
      completeMessage: "Your support is appreciated. Let’s hope this one works out as intended…",
      mediaType: "video",
      link: "https://www.youtube.com/embed/P9qQrGpxgsA",
      links:[],
      mediaType2: "image",
      link2: HOMEPAGE.JOURNEY3PUZZLE,
      reward: {
        selectionHash: "0x4e22fd59634520f0c1044fbbf6045edb965f0cee",
        selectionLink: "https://polygonscan.com/tx/0x3a9cc0f05c03bbccd0daecfaa38c989c67a2e574ccfe961d2785e8ce62e671b2#eventlog",
        data: [
            {
                token_id: "4754",
                winner: "0x364262d40eedc00cb22ca3ca574563f39aa52e67",
                token_type: "CAT",
            },
            {
                token_id: "11566",
                winner: "0xfc5620bd71a760e5b074bb7aaf29bda593b93ad7",
                token_type: "FRAC",
            },
            {
                token_id: "11567",
                winner: "0x8d6aaa6807dff81d213f59ec95b985816fe40d8b",
                token_type: "FRAC",
            },
            {
                token_id: "11568",
                winner: "0x9f4ade5216948603f9f9fd2112f8a70e3d23274b",
                token_type: "FRAC",
            },
            {
                token_id: "11569",
                winner: "0x546937734ce4d9553bf70b9c18ad9d4408aab94f",
                token_type: "FRAC",
            },
            {
                token_id: "11570",
                winner: "0xc3082af35fe9747d996c8913212e7cd28982081a",
                token_type: "FRAC",
            },
            {
                token_id: "11571",
                winner: "0x3c6d1026bab072257653306cfe0b9222c2e0432b",
                token_type: "FRAC",
            },
            {
                token_id: "11572",
                winner: "0xd4091d661a44648d61bd3bb51e129d0d60892056",
                token_type: "FRAC",
            },
            
        ]
    }
    },
    // {
    //   number: 4,
    //     title: "Old Man Berg",
    //     type: "normal",
    //   startDate: "3.23.2023 15:00:00",
    //   stakingCloseDate: "3.27.2023 15:00:00",
    //   endDate: "4.06.2023 00:00:00",
    //   markerPosition: {
    //     top: 59.3,
    //     right: 65.4,
    //   },
    //   joinSignMessage: "I hope this old man is taking us to get Mexican Pizzas and Baja Blast.",
    //   rewardSignMessage: "Mumble mumble mumble.. Guinea pig.. Mumble mumble mumble.",
    //   question: "Puzzle Answer:",
    //   pathQuestion: "Fracture training sounds dangerous, should we trust Old Man Berg?",
    //   completeMessage: "Fracture training will not be easy, and likely not fun. Let’s see if we can find some help first.",
    //   mediaType: "image",
    //   links: [JOURNEY4.COMIC1, JOURNEY4.COMIC2, JOURNEY4.COMIC3, JOURNEY4.COMIC4, JOURNEY4.COMIC5, JOURNEY4.COMIC6, JOURNEY4.COMIC7, JOURNEY4.COMIC8, JOURNEY4.COMIC9],
    //   mediaType2: "image",
    //   link2: JOURNEY4.PUZZLE,
    //   reward: {
    //     selectionHash: "0x4e22fd59634520f0c1044fbbf6045edb965f0cee",
    //     selectionLink: "https://polygonscan.com/tx/0x9b64557934e72dbe6081ba24bda300b4aeeed3936af77b588f29339a950d1684#eventlog",
    //     data: [
    //         {
    //             token_id: "4753",
    //             winner: "0x0370cfcd4a11a99035dc127420b814055890ac6c",
    //             token_type: "CAT",
    //         },
    //         {
    //             token_id: "3317",
    //             winner: "0x8d03e68e7be9d5d54f9938d5595c32b07d881b9e",
    //             token_type: "FRAC",
    //         },
    //         {
    //             token_id: "11529",
    //             winner: "0x1824db66ae6d644f0be2aec44eb0638b76e9a6a7",
    //             token_type: "FRAC",
    //         },
    //         {
    //             token_id: "11522",
    //             winner: "0xba65fe8c700367973f4f5c0ae239ea41455009f5",
    //             token_type: "FRAC",
    //         },
    //         {
    //             token_id: "11534",
    //             winner: "0xa898d9a43d18df8fadd6ee242b49d26b4c1bcd28",
    //             token_type: "FRAC",
    //         },
    //         {
    //             token_id: "11536",
    //             winner: "0x61fe0eedb1b8ef8504ef86b89a625311260ab87b",
    //             token_type: "FRAC",
    //         },
    //         {
    //             token_id: "11559",
    //             winner: "0xb7a5ae7cd659cd3d75a14f53c97dbdba6d12eb15",
    //             token_type: "FRAC",
    //         },
    //         {
    //             token_id: "11562",
    //             winner: "0xaa3cd3220461450d9bca0c6ec9b738799e8c39dc",
    //             token_type: "FRAC",
    //         },
            
    //     ]
    // }
    // },
    // {
    //   number: 5,
    //     title: "An Unlikely Fren",
    //     type: "normal",
    //   startDate: "4.06.2023 00:00:00",
    //   stakingCloseDate: "4.10.2023 15:00:00",
    //   endDate: "4.20.2023 00:00:00",
    //     markerPosition: {
    //         top: 47,
    //         right: 53.4
    //     },
    //     contentCopy: [],
    //     puzzleCopy: ["..."],
    //   joinSignMessage: " I wonder how Chugs is doing..Probably fine, right? RIGHT?!",
    //   rewardSignMessage: "Yet, still no Baja Blast…",
    //   question: "Puzzle Answer:",
    //     pathQuestion: "Who should use the staff to open the fracture?",
    //     leftButton: "",
    //     rightButton: "",
    //   completeMessage: "...",
    //   mediaType: "image",
    //   links: [JOURNEY5.COMIC1, JOURNEY5.COMIC2, JOURNEY5.COMIC3, JOURNEY5.COMIC4, JOURNEY5.COMIC5, JOURNEY5.COMIC6, JOURNEY5.COMIC7, JOURNEY5.COMIC8],
    //   reward: {}
    // },
    {
        number: 6,
        title: "(Un)Expected Results",
        type: "normal",
        startDate: "4.27.2023 15:00:00",
        stakingCloseDate: "5.01.2023 15:00:00",
        endDate: "5.01.2023 00:00:00",
        markerPosition: {
          top: 50.3,
          right: 57.4,
        },
        contentCopy: [],
        puzzleCopy: ["..."],
        joinSignMessage: "Looking at those elders, it's not looking good for the milk carton.",
        rewardSignMessage: "This is not a meme, but I love you. (♥_♥)",
        question: "What is the two word phrase?",
        pathQuestion: "After seeing months of puzzles, should they be…",
        leftButton: "",
        rightButton: "",
        completeMessage: "Old Man Berg seems to create nothing but trouble. Let’s see how you did.",
        mediaType: "video",
        links: [JOURNEY6.COMIC1, JOURNEY6.COMIC2, JOURNEY6.COMIC3, JOURNEY6.COMIC4, JOURNEY6.COMIC5, JOURNEY6.COMIC6],
        mediaType2: "image",
        link2: JOURNEY6.PUZZLE,
        reward: {}
    },
    {
        number: 7,
        title: "Ardi’s Workshop",
        type: "normal",
        startDate: "5.11.2023 15:00:00",
        stakingCloseDate: "5.15.2023 15:00:00",
        endDate: "5.18.2023 00:00:00",
        markerPosition: {
            top: 50.3,
            right: 60.4,
        },
        contentCopy: [],
        puzzleCopy: ["..."],
        joinSignMessage: "These Aurorium fragments look like they will have some significant meaning… Sensational!",
        rewardSignMessage: "No whammies….",
        question: "List the 3 missing items one at a time separated by commas.",
        pathQuestion: "Which path should Ardi and Blue take?",
        leftButton: "",
        rightButton: "",
        completeMessage: "Ardi’s tablet seems to be taking us to the Marshmallow Mountains. Let’s hope this doesn’t turn into one of his “learning” experiences.",
        mediaType: "image",
        links: [JOURNEY7.JOURNEYCOMIC],
        mediaType2: "image",
        link2: DESKPUZZLE.PUZZLEBG,
        reward: {}
    },
    {
        number: 8,
        title: "Chugs and Jo Part 1",
        type: "shadow",
        startDate: "5.18.2023 15:00:00",
        stakingCloseDate: "5.23.2023 15:00:00",
        endDate: "6.01.2023 00:00:00",
        markerPosition: {
          top: 50.3,
          right: 10.4,
        },
        joinSignMessage: "“The milk carton is picking flowers instead of escaping. I am SURE this will work out well…”",
        rewardSignMessage: "Don’t forget to put in your Macy’s vote! The Cool Cats could win a parade balloon for the 2023 Thanksgiving Day Parade!",
        contentCopy: ["Meanwhile back in the Wandering Territory..."],
        puzzleCopy: ["..."],
        question: "Count all of the fallen leaves, small rocks, and insects. What is the sum of those three numbers?",
        pathQuestion: "Which flower should Chugs pick?",
        leftButton: "",
        rightButton: "",
        completeMessage: "The ripple effect of one simple decision. This purple flower, this emblem of our shared friendship, will undoubtedly cast its influence across Cooltopia. Its effects may not be immediately visible, but over time, it will subtly shape our shared future. Await, my friends, for the unfolding of this fascinating chronicle.",
        mediaType: "image",
        links: [JOURNEY8.JOURNEYCOMIC1, JOURNEY8.JOURNEYCOMIC2, JOURNEY8.JOURNEYCOMIC3, JOURNEY8.JOURNEYCOMIC4, JOURNEY8.JOURNEYCOMIC5],
        mediaType2: "image",
        link2: "",
        reward: {}
      },
    {
        number: 9,
        title: "Marshmallow Mountains",
        type: "normal",
        startDate: "5.25.2023 15:00:00",
        stakingCloseDate: "5.29.2023 15:00:00",
        endDate: "6.08.2023 00:00:00",
        markerPosition: {
          top: 20.3,
          right: 70.4,
        },
        joinSignMessage: "Chalupas and Choco Tacos.",
        rewardSignMessage: "These rewards must be useful.. But for what? Aurorium and Umbrium. Mmm… Tacos.",
        contentCopy: ["“Every two to three centuries the Marshmallow Mountains have erupted and laid waste to the nearby land. Sapphire Town is a huge city that had to be fully rebuilt after the previous rush of Lava Mallow poured in destroying all of the infrastructure.", "Luckily the mountains seemed to have died down in recent times.”"],
        puzzleCopy: ["Can you figure out the names of the peaks in the Marshmallow Mountains?"],
        question: "List the mountain names from shortest to tallest separated by commas.",
        pathQuestion: "Which is more interesting?",
        leftButton: "Flora",
        rightButton: "Fauna",
        completeMessage: "The Marshmallow Mountains were further than expected, but here we are ready to explore. Let’s claim our prizes! ",
        mediaType: "image",
        links: [JOURNEY9.JOURNEYCOMIC],
        mediaType2: "image",
        link2: JOURNEY9.JOURNEYPUZZLE,
        reward: {}
    },
    {
        number: 10,
        title: "Chugs and Jo Part 2",
        type: "shadow",
        startDate: "6.01.2023 15:00:00",
        stakingCloseDate: "6.05.2023 15:00:00",
        endDate: "6.15.2023 00:00:00",
        markerPosition: {
          top: 50.3,
          right: 13.4,
        },
        joinSignMessage: "By signing this message you agree to take it easy on Clon any time you play him in the upcoming mobile game - sometimes even losing on purpose. If Clon wins, we all win. ",
        rewardSignMessage: "Handing out mustaches and round glasses at Comic Clon 2023. Who’s coming with me?",
        contentCopy: [""],
        puzzleCopy: [""],
        question: "Which two paths lead to Wolfrick’s mouth? Enter the two numbers separated by a comma.",
        pathQuestion: "Which is more interesting?",
        leftButton: "Inside",
        rightButton: "Outside",
        completeMessage: "After seemingly endless hours, if not days, confined in a cage, Jo and Chugs raise their weary eyes to the imposing silhouette of Kira's Den, a daunting spectacle looming ominously ahead.",
        mediaType: "carousel",
        links: [JOURNEY10.JOURNEYCOMIC1, JOURNEY10.JOURNEYCOMIC2, JOURNEY10.JOURNEYCOMIC3, JOURNEY10.JOURNEYCOMIC4],
        mediaType2: "image",
        link2: JOURNEY10.JOURNEYPUZZLE,
        reward: {}
    },
    {
        number: 11,
        title: "Blue and Ardi Part 1",
        type: "normal",
        startDate: "6.08.2023 15:00:00",
        stakingCloseDate: "6.12.2023 15:00:00",
        endDate: "6.22.2023 00:00:00",
        markerPosition: {
          top: 17.3,
          right: 73.4,
        },
        joinSignMessage: "THE Milo? No. No way. No how. No, Chance.",
        rewardSignMessage: "Yo, Cool Cats are cool.",
        contentCopy: [""],
        puzzleCopy: [""],
        question: "How many triangles were in the image?",
        pathQuestion: "Which is more interesting?",
        leftButton: "Darkness",
        rightButton: "Light",
        completeMessage: "Ah, our adventurous little Milo! He certainly knows how to tiptoe along the edge of danger. Let's hope his curiosity continues to fuel his adventures, but not lead him too close to the jaws of peril again. Onwards, Milo, but tread carefully!",
        mediaType: "carousel",
        links: [JOURNEY11.JOURNEYCOMIC1, JOURNEY11.JOURNEYCOMIC2, JOURNEY11.JOURNEYCOMIC3, JOURNEY11.JOURNEYCOMIC4, JOURNEY11.JOURNEYCOMIC5],
        mediaType2: "image",
        link2: JOURNEY11.JOURNEYPUZZLE,
        reward: {}
    },
    {
        number: 12,
        title: "Kira’s Den",
        type: "shadow",
        startDate: "6.15.2023 00:00:00",
        stakingCloseDate: "6.19.2023 15:00:00",
        endDate: "6.29.2023 00:00:00",
        markerPosition: {
          top: 45.3,
          right: 18.4,
        },
        joinSignMessage: "Here’s some eye candy for your feed… \n\n 8==a;lksadfj aseaSE \n\n ..the previous writer of these messages has been terminated. Sorry about that.",
        rewardSignMessage: "Poor Milo…",
        contentCopy: [`"Perched on a craggy hill in Howling Weald, Kira's Den stands tall, a rustic yet imposing fortress. Surrounded by fiercely burning torches and studded with spikes and tooth-like structures, it is an untouchable monument of power. Despite its foreboding exterior, it seamlessly integrates with the mountain, offering breathtaking panoramic views of the surrounding landscape. A silent sentinel, Kira's Den is a rugged jewel in the heart of the Cooltopia."`],
        puzzleCopy: [""],
        question: "What is the largest whole number that can be made by moving only two pencils?",
        pathQuestion: "",
        leftButton: "Mercy",
        rightButton: "Wrath",
        completeMessage: "Indeed, the stones you stumbled upon were not mere trinkets. \n\n\n Let’s see our rewards for this leg of the journey…",
        mediaType: "image",
        links: [JOURNEY12.JOURNEYCOMIC],
        mediaType2: "image",
        link2: JOURNEY12.JOURNEYPUZZLE,
        reward: {}
    },
    {
        number: 13,
        title: "Temple of the Dark Star",
        type: "normal",
        startDate: "6.22.2023 15:00:00",
        stakingCloseDate: "6.26.2023 15:00:00",
        endDate: "7.06.2023 00:00:00",
        markerPosition: {
          top: 10.3,
          right: 73.4,
        },
        joinSignMessage: "A cage match between the two social media giants should be interesting… \n\n\n .. My money is on Tom from Myspace.",
        rewardSignMessage: "Confession: I’m obsessed with Cool Cats. \n\n\n And Tacos. \n\n\n Mmm… Taco Cats.",
        contentCopy: ["“A mystery unveiled, the Temple of the Dark Star opens its doors. Its interior, cloaked in centuries of secrecy, reveals an imposing altar surrounded by treasures of yesteryears, their owners long gone. Behind this altar, an unsettling black void pulsates, marking a passage into the unknown. The air is heavy with the echoes of ancient incantations, and the walls are adorned with inscrutable symbols, silent witnesses of forgotten rituals. As the veil of the past lifts, what will we find?”"],
        puzzleCopy: ["Slide the tiles to reveal the temple"],
        question: "",
        pathQuestion: "Choose how you believe we should proceed.",
        leftButton: "Cautious",
        rightButton: "Reckless",
        completeMessage: "Let’s see what the RNG gods have brought us this Journey…",
        mediaType: "image",
        links: [JOURNEY13.JOURNEYCOMIC],
        mediaType2: "image",
        link2: JOURNEY11.JOURNEYPUZZLE,
        reward: {}
    },
    {
        number: 14,
        title: "Chugs and Jo Part 3",
        type: "shadow",
        startDate: "6.29.2023 15:00:00",
        stakingCloseDate: "7.03.2023 15:00:00",
        endDate: "7.13.2023 00:00:00",
        markerPosition: {
            top: 47.3,
            right: 8.4,
          },
        joinSignMessage: "I am starting to feel like cool cats are underestimated.",
        rewardSignMessage: "Community over everything.",
        contentCopy: [``],
        puzzleCopy: [""],
        question: "What is the two word phrase?",
        pathQuestion: "",
        leftButton: "Heads",
        rightButton: "Tails",
        completeMessage: "It is time to meet Kira, remember, don’t forget to make eye contact.",
        mediaType: "carousel",
        links: [JOURNEY14.JOURNEYCOMIC1, JOURNEY14.JOURNEYCOMIC2, JOURNEY14.JOURNEYCOMIC3, JOURNEY14.JOURNEYCOMIC4],
        mediaType2: "image",
        link2: JOURNEY14.JOURNEYPUZZLE,
        reward: {}
    },
    {
      number: 15,
      title: "What.. Happened?",
      type: "normal",
      startDate: "7.06.2023 15:00:00",
      stakingCloseDate: "7.10.2023 15:00:00",
      endDate: "7.20.2023 00:00:00",
      markerPosition: {
        top: 12.3,
        right: 70.4,
      },
      joinSignMessage: "Buckle up, frens.",
      rewardSignMessage: "Rule #1: Don't believe anything JARVIS posts on Twitter.",
      contentCopy: ["What...Happened?", "As the ancient doors to the Temple of the Dark Star creaked open, our adventurous Milo caught a glint of something irresistibly shiny deep within the darkness. Unable to resist, he dashed towards the gleaming curiosity. In the next moment, a blinding, radiant burst consumed the room, forcing us to shield our eyes.", "The dazzling radiance ebbed with each blink, slowly revealing a scene that left us in stunned silence. \"Milo!! Are you okay?!\" The words barely left our lips when an unfamiliar silhouette began to emerge from the luminous haze. Milo had changed; he was something... more.", "This event left us wondering - what other creatures could be transformed in this manner? It was clear, however, that our companions had evolved beyond what we once knew as 'pets.' The Cooltopia we thought we knew was changing, one brilliant flash of light at a time."],
      puzzleCopy: ["Find the hidden message:"],
      question: "What is the hidden message?",
      pathQuestion: "Are you enjoying the journeys? ",
      leftButton: "No!",
      rightButton: "Love Them!",
      completeMessage: "Milo in his native form is great, but wait until you see him in all of his other forms!!",
      mediaType: "image",
      links: [JOURNEY15.JOURNEYCOMIC],
      mediaType2: "image",
      link2: JOURNEY15.JOURNEYPUZZLE,
      reward: {}
  },
  {
    number: 16,
    title: "The Menacing Adversary",
    type: "shadow",
    startDate: "7.13.2023 15:00:00",
    stakingCloseDate: "7.17.2023 15:00:00",
    endDate: "7.27.2023 00:00:00",
    markerPosition: {
        top: 47.3,
        right: 5.4,
      },
    joinSignMessage: "You have exceeded your annual rate limit for funny/witty signature messages. \n\n\n Please refresh or try again next year.",
    rewardSignMessage: "A cozy blanket is perfect for a bear market.",
    contentCopy: [`“Kira, once a wolf from Blue's Homeworld, now leads the pack in Cooltopia. Her startling cloak, set with pure Vizium, mirrors her stark gaze - one eye a cool blue, the other, a fierce yellow. Driven by a desperate desire to reunite with her pack, she stands as a formidable antagonist. Her quick temper and witty quips are balanced by a highly logical mind, making her a cunning strategist.`, `So tread carefully, my friends, for in the land of Cooltopia, Kira is not just another shadow... she is the storm before the calm.”`],
    puzzleCopy: [""],
    question: "What is the hidden message?",
    pathQuestion: "What kind of companion would be better…?",
    leftButton: "Demonic",
    rightButton: "Nerdy",
    completeMessage: "“Chapter 2 is coming to an end, let’s see if there are any more rewards in the bucket.”",
    mediaType: "image",
    links: [JOURNEY16.JOURNEYCOMIC],
    mediaType2: "image",
    link2: JOURNEY14.JOURNEYPUZZLE,
    reward: {}
},
{
  number: 17,
  title: "MILO!",
  type: "normal",
  startDate: "7.20.2023 15:00:00",
  stakingCloseDate: "7.24.2023 15:00:00",
  endDate: "8.03.2023 00:00:00",
  markerPosition: {
    top: 4.3,
    right: 73.4,
  },
  joinSignMessage: "Rule #2: Believe everything JARVIS posts on Twitter.",
  rewardSignMessage: "",
  contentCopy: ["MILO!"],
  puzzleCopy: [""],
  question: "How many squares were in the image?",
  pathQuestion: "Who’s ready for a pet upgrade?",
  leftButton: "Not me!",
  rightButton: "Me!",
  completeMessage: "",
  mediaType: "image",
  links: [JOURNEY17.JOURNEYCOMIC],
  mediaType2: "image",
  link2: JOURNEY17.JOURNEYPUZZLE,
  reward: {}
},

{
  number: 18,
  title: "Kira’s Sixth Sense",
  type: "shadow",
  startDate: "7.27.2023 15:00:00",
  stakingCloseDate: "07.31.2023 15:00:00",
  endDate: "8.10.2023 00:00:00",
  markerPosition: {
      top: 47.3,
      right: 5.4,
    },
  joinSignMessage: "Fun Fact: The Reddit Snoo was named after Reddit’s ..\n\n\n“What’s New” tab.\n\n\nThe more you know.",
  rewardSignMessage: "...",
  contentCopy: [`“In the foreboding shadows, Kira's piercing blue eye reveals a scene of unfolding destiny. Reflected within its frosty azure depths is the incandescent flash of the Temple of the Dark Star Elder - its ancient power now awakened. Can it be the handiwork of the newcomers, the bird and the milk carton?`, `Panic stirs within the heart of Jo and Chugs, their senses alive with the prickling chill of heightened danger. In the cold half-light, their confined figures huddle, eyes wide and fearful. Beside Kira, her new sidekick bristles, its ethereal form rippling with disquiet.
  `, `The fates are being woven anew in Cooltopia, and Kira's gaze holds a silent promise of mysteries yet to unravel.”`],
  puzzleCopy: [""],
  question: "What is the hidden message?",
  pathQuestion: "Jo or Chugs?",
  leftButton: "Jo",
  rightButton: "Chugs",
  completeMessage: "...",
  mediaType: "image",
  links: [JOURNEY18.JOURNEYCOMIC],
  mediaType2: "image",
  link2: JOURNEY18.JOURNEYPUZZLE,
  reward: {}
},
];


export default journeys